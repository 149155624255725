<template>
  <div class="page bg-white recordQueryBox">
    <div class="flex_l_c">
      <div class="flex_l_c srhBox">
        <el-input v-model="searchForm.keyword" clearable maxlength="50" @keyup.enter.native="search(1)"
                  placeholder="请输入关键词进行检索，多个关键词用空格分开（限50字）"></el-input>
        <el-button type="primary" @click="search(1)">检索</el-button>
        <el-button @click="reset()" icon="el-icon-refresh-right">重置</el-button>
      </div>
      <div class="tips2">对检索结果不满意？试试
        <el-button type="text" @click="advancedSearch()">高级检索</el-button>
      </div>
    </div>
<!--    <div class="tips">-->
<!--      <el-button type="text" @click="searchInputShow = !searchInputShow">更多选项</el-button>-->
<!--      本次检索共为您找到相关结果约{{ total }}个-->
<!--    </div>-->
<!--    <transition name="el-zoom-in-top">-->
<!--      <div v-show="searchInputShow" class="transition-box">-->
<!--        <el-form size="small" ref="searchForm" :inline="true"-->
<!--                 :model="searchForm" label-width="120px">-->
<!--          <el-form-item label="藏品类型" prop="collectionType">-->
<!--            <el-select v-model="searchForm.collectionType"-->
<!--                       placeholder="请选择藏品类型"-->
<!--                       style="width: 100%"-->
<!--                       clearable>-->
<!--              <el-option-->
<!--                  v-for="item in typeList"-->
<!--                  :key="item.id"-->
<!--                  :label="item.collectionTypeName"-->
<!--                  :value="item.id">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="藏品分类" prop="archivesBasicDataId">-->
<!--            <treeSelect ref="menuParentTree"-->
<!--                        :props="{-->
<!--                                            children: 'children',-->
<!--                                            label: 'archivesBasicName',-->
<!--                                            value:'id'-->
<!--                                             }"-->
<!--                        :data="classList"-->
<!--                        :value="searchForm.archivesBasicDataId"-->
<!--                        :clearable="true"-->
<!--                        :accordion="true"-->
<!--                        @getValue="selectCascader"-->
<!--            ></treeSelect>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="是否鉴定" prop="identified">-->
<!--            <el-select v-model="searchForm.identified"-->
<!--                       placeholder="请选择" style="width: 100%"-->
<!--                       clearable>-->
<!--              <el-option-->
<!--                  v-for="item in identifyList"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="级别" prop="collectionLevel">-->
<!--            <el-select v-model="searchForm.collectionLevel"-->
<!--                       placeholder="请选择藏品级别"-->
<!--                       style="width: 100%"-->
<!--                       clearable>-->
<!--              <el-option-->
<!--                  v-for="item in leaveList"-->
<!--                  :key="item.level"-->
<!--                  :label="item.levelName"-->
<!--                  :value="item.level">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="完残程度" prop="integrity">-->
<!--            <el-select v-model="searchForm.integrity"-->
<!--                       placeholder="请选择完残程度"-->
<!--                       style="width: 100%"-->
<!--                       clearable>-->
<!--              <el-option-->
<!--                  v-for="item in integrityList"-->
<!--                  :key="item.id"-->
<!--                  :label="item.levelName"-->
<!--                  :value="item.id">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="完残情况" prop="integrityInfo">-->
<!--            <el-input v-model="searchForm.integrityInfo"-->
<!--                      placeholder="请输入完残情况(限50字)"-->
<!--                      maxlength="50" clearable></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="库存状态" prop="cStoreState">-->
<!--            <el-select v-model="searchForm.cStoreState"-->
<!--                       placeholder="请选择库存状态"-->
<!--                       style="width: 100%"-->
<!--                       clearable>-->
<!--              <el-option-->
<!--                  v-for="item in this.$dictUtils.getDictList('cStore_state')"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value"-->
<!--              >-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--        </el-form>-->
<!--      </div>-->
<!--    </transition>-->
    <div class="flex_b_c magTop">
      <div>
        <el-button type="primary" size="small" v-show="hasPermission('workbench:dataRetrieval:special')"
                   :disabled="!dataListSelections.length" @click="special()">加入专题
        </el-button>
        <el-button type="primary" size="small" v-show="hasPermission('workbench:dataRetrieval:collect')"
                   :disabled="!dataListSelections.length" @click="collect()">加入收藏
        </el-button>
      </div>
      <div>
        <el-button size="small"
                   v-show="hasPermissionButton('workbench:dataRetrieval:export')"
                   @click="exportl()">
          <i class="icon-piliangdaochu iconfont buIcon"/>
          批量导出
        </el-button>
      </div>
    </div>

    <el-table
        :data="dataList"
        v-loading="loading"
        size="small"
        height="calc(100vh - 315px)"
        @selection-change="selectionChangeHandle"
        class="table" ref="multipleTable" row-key='id'>
      <el-table-column :reserve-selection="true" type="selection" width="50"/>
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column prop="name" label="图标" width="120px">
        <template slot-scope="scope">
          <div class="flex_a_c">
            <div v-if="scope.row.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
            <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.picMasterViewUrl"
                :fit="'cover'"
                :preview-src-list="[scope.row.picMasterViewUrl]"
            >
              <div slot="error" class="image-slot">
                <el-image
                    style="width: 36px; height: 36px"
                    :src="require('@/assets/img/default.png')"
                    :fit="'cover'">
                </el-image>
              </div>
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="collectionName" label="藏品名称"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <div v-html="scope.row.collectionName"></div>
        </template>
      </el-table-column>
      <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip>
        <template slot-scope="scope">
          <div v-html="scope.row.helpNum"></div>
        </template>
      </el-table-column>
      <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip>
        <template slot-scope="scope">
          <div v-html="scope.row.generalNum"></div>
        </template>
      </el-table-column>
      <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip>
        <template slot-scope="scope">
          <div v-html="scope.row.integrityInfo"></div>
        </template>
      </el-table-column>
      <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip>
        <template slot-scope="scope">
          <div v-html="scope.row.integrity"></div>
        </template>
      </el-table-column>
      <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}
        </template>
      </el-table-column>
      <el-table-column v-for="(item, index) in config" :key="item.archivesBasicId"
                       :prop="item.code"
                       width="150px"
                       :sortable="item.archivesBasicTypeCode == 'date' || item.archivesBasicTypeCode == 'integer' ? 'custom' : false"
                       :label="item.basicName" v-if="item.dataSelect == 0" min-width="120"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <div v-html="scope.row.archivesData[item.code]"></div>
        </template>
      </el-table-column>
      <el-table-column prop="createUserName" label="创建者"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="createDate" sortable label="创建时间" width="150px">
        <template slot-scope="scope">
          {{ scope.row.createDate | formatDate }}
        </template>
      </el-table-column>
      <el-table-column prop="updateUserName" label="最后更新人"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="updateDate" sortable label="最后更新时间" width="150px"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.updateDate | formatDate }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" :width="listConfig.length ? '120': ''" label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small"
                     v-show="hasPermission('workbench:dataRetrieval:view')"
                     @click="view(scope.row, 'view')">详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="searchForm.current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="searchForm.size"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <ExportData ref="exportData" @downLoad="search(0)"></ExportData>
    <AdvancedSearch ref="advancedSearch" @getDataList="getSrhCondition"></AdvancedSearch>
    <special-list ref="specialList" @specialYs="search(0)"></special-list>
  </div>
</template>

<script>
import SelectTree from '@/components/treeSelect/treeSelect.vue'
import AdvancedSearch from '@/components/advancedSearch/advancedSearch'
import treeSelect from "@/components/treeSelect/treeSelect.vue";
import ExportData from "@/views/modules/record/collect/exportData.vue";
import SpecialList from "@/views/modules/workbench/specialList.vue";

export default {
  components: {
    SpecialList,
    ExportData,
    treeSelect,
    SelectTree,
    AdvancedSearch
  },
  data() {
    return {
      searchForm: {
        keyword: '',
        // archivesBasicDataId: null,
        // collectionType: '',
        // identified: '',
        // collectionLevel: '',
        // integrity: '',
        // integrityInfo: '',
        // cStoreState: '',

        size: 10,
        current: 1,
        whereList: [],
        fieldWhereList: [],
        "fieldList": [],
        "fieldOrList": [],
        highlight: true,
      },
      searchInputShow: false,
      config: [],
      listConfig: [],
      advancedList: [],
      dataList: [],
      dataListSelections: [],
      loading: false,
      total: 0,

      typeList: [],
      classList: [],
      identifyList: [
        {
          label: '是',
          value: 0,
        },
        {
          label: '否',
          value: 1,
        },
      ],
      leaveList: [],
      integrityList: [
        {
          id: '残缺',
          levelName: '残缺',
        },
        {
          id: '基本完整',
          levelName: '基本完整',
        },
        {
          id: '完整',
          levelName: '完整',
        },
        {
          id: '严重残缺',
          levelName: '严重残缺',
        },
      ],
    }
  },
  mounted() {
    this.getSelectData()
  },
  methods: {
    // 检索
    search(type) {
      if (type == 1) {
        this.searchForm.current = 1
      }
      if (type == 0) {
        this.$refs.multipleTable.clearSelection()
      }
      this.loading = true
      let archivesBasicDataId = null
      if (this.searchForm.archivesBasicDataId) {
        archivesBasicDataId = [this.searchForm.archivesBasicDataId]
      }
      let fieldOrList = [
        {
          "archivesBasicTypeMethodCode": "=",
          "key": "entryState",
          "theKey": "",
          "value": "1"
        }, {
          "archivesBasicTypeMethodCode": "=",
          "key": "entryState",
          "theKey": "",
          "value": "2"
        }
      ]
      let fieldList = [
        {
          "archivesBasicTypeMethodCode": "=",
          "key": "processState",
          "theKey": "",
          "value": "3"
        }
      ]
      this.$axios(this.api.collection.queryDatabaseSearchPage, {
        fieldWhereList: this.searchForm.fieldWhereList,
        archivesBasicDataId,
        fieldList,
        fieldOrList,
        whereList: this.searchForm.whereList,
        keyword: this.searchForm.keyword,
        current: this.searchForm.current,
        size: this.searchForm.size,
      }, 'post').then(res => {
        if (res.status) {
          this.dataList = res.data.records
          this.total = parseInt(res.data.total)
        } else {
          this.$message.error('查询失败');
        }
        this.loading = false
        this.$nextTick(() => {
          this.$refs.multipleTable.doLayout()
        })
      })

    },

    // 加入收藏
    collect(item) {
      let list = []
      list = this.dataListSelections.map(v => {
        return {
          archivesBasicDataId: v.archivesBasicDataId,
          archivesDataId: v.id,
          favoritesType: 1,
        }
      })
      this.$confirm(`确定加入收藏吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.collection.archivespersonalfavoritesSave, list, 'post').then(data => {
          if (data.status) {
            this.$message.success('收藏成功')
            this.search(0)
          } else {
            this.$message.error('收藏失败')
          }
        })
      })
    },

    //加入专题
    special() {
      this.$refs.specialList.init(this.dataListSelections)
    },

    //重置
    reset() {
      this.searchForm.keyword = ''

      this.searchForm.whereList = []
      this.searchForm.fieldWhereList = []
      this.advancedList = []
      this.search(1)
    },

    //导出
    exportl() {
      if (!this.dataListSelections.length) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      let ids = this.dataListSelections.map(item => {
        if (item) {
          return {
            id: item.id,
            archivesBasicDataId: item.archivesBasicDataId
          }
        }
      })
      this.$refs.exportData.init(ids, this.config, this.archivesBasicDataId, '藏品数据', 1)
    },

    // 高级检索
    advancedSearch() {
      this.$refs.advancedSearch.init([], this.advancedList, 'data')
    },
    // 获取高级检索条件
    getSrhCondition(data) {
      if (data) {
        this.advancedList = data.advancedList
        this.searchForm.whereList = data.whereList
        this.searchForm.fieldWhereList = data.fieldWhereList
        this.search(1)
      }
    },
    // 查看
    view(row, method) {
      let listSearch = {
        ...this.searchForm,
        advancedList: this.advancedList,
        searchInputShow: this.searchInputShow,
      }
      sessionStorage.setItem('listSearch', JSON.stringify(listSearch))

      this.$router.push({
        path: '/collection/accounts/addCollection',
        query: {
          butType: 3,
          id: row.id,
          archivesBasicDataId: row.archivesBasicDataId,
          typePage: 'ledger',
          see: true
        }
      })
    },

    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 每页数
    sizeChangeHandle(val) {
      this.searchForm.size = val
      this.searchForm.current = 1
      this.search()
    },
    // 当前页
    currentChangeHandle(val) {
      this.searchForm.current = val
      this.search()
    },

    getSelectData() {
      this.$axios(this.api.collection.listLevelSelect).then(data => {
        if (data.status) {
          this.leaveList = data.data
        }
      })
      this.$axios(this.api.collection.listSelect).then(data => {
        if (data.status) {
          this.typeList = data.data
        }
      })
      this.$axios(this.api.collection.selectArchivesBasicData, {}, 'post').then((res) => {
        if (res.status) {
          this.classList = res.data
          let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
          if (listSearch) {
            this.advancedList = listSearch.advancedList
            this.searchInputShow = listSearch.searchInputShow
            sessionStorage.removeItem('listSearch')
            this.$nextTick(() => {
              this.searchForm = listSearch
              this.selectCascader(listSearch.archivesBasicDataId)
            })
          } else {
            this.searchForm.keyword = this.$route.query.keyword
            this.selectCascader()
          }
        }
      })
    },

    //藏品分类级联选择
    selectCascader(value) {
      this.searchForm.archivesBasicDataId = value
      let getByIdUrl = this.api.collection.getArchivesBasicByRequired
      if (value) {
        getByIdUrl = this.api.collection.basicdataGetById + '/' + value
      }
      this.$axios(getByIdUrl).then(data => {
        if (data.status) {
          this.config = data.data.archivesBasicDataGroup
          this.config.map(item => {
            if (item.dataWhere == 0) {
              this.$set(item, 'value', '')
              this.$set(this.inputForm, item.code, '')
              if (item.archivesBasicTypeCode == 'integer') {
                this.$set(this.rules, item.code, [{
                  validator: this.validator.isDigits,
                  trigger: 'blur'
                }])
              }
            }
          })
        }
      })
      this.search(1)
    },
  },
}
</script>

<style scoped lang="scss">
.recordQueryBox {
  .srhBox {
    width: 60%;
    margin: 0;
  }
}

.tips {
  font-size: 13px;
  color: #999999;
  padding: 12px 0;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 15px;
}

.tips2 {
  font-size: 13px;
  color: #999999;
  padding-left: 30px;
}

.bg-white {
  overflow-y: auto !important;
}

.magTop{
  margin-top: 15px;
}
</style>
